import { graphql, Link } from 'gatsby';
import React, { FC } from 'react';

import App from '../components/App';
import Button from '../components/Button';
import CategoryTitle from '../components/CategoryTitle';
import Posts from '../components/Posts';

const TagsList: FC<{ data: any }> = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const { tag } = edges[0].node.frontmatter;

  return (
    <App>
      <CategoryTitle category={tag} />
      <Posts edges={edges} />
      <Button to="/tags/">All tags</Button>
      <Link to="/tags" />
    </App>
  );
};

export default TagsList;

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tag: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 100)
          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY", locale: "en")
            title
            category
            tag
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 450
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      group(field: id) {
        nodes {
          frontmatter {
            tag
          }
        }
      }
    }
  }
`;
