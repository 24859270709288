/* eslint-disable max-len */
import { Link } from 'gatsby';
import React, { FC, ReactNode } from 'react';

const Button: FC<{ to: string; children: ReactNode; onClick?: any }> = ({
  to,
  children,
  onClick,
}) => (
  <Link
    className="border md:my-10 md:py-3 p-3 mb-6 w-36 inline-block bg-black hover:bg-lightBlack text-white m-auto"
    onClick={onClick}
    to={to}
  >
    {children}
  </Link>
);

export default Button;
